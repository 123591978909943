import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Retry interceptor function
import axiosRetry from 'axios-retry';

// Pass the axios instance to the retry function  and call it
axiosRetry(axios, { 
  retries: 1, // Number of retries
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
  retryCondition(error) {
    // Conditional check the error status code
    switch (error.response.status) {
      case 500:
        return true; // Retry request with response status code 500
      case 400:
        return true; // Retry request with response status code 400
      default:
        return false; // Do not retry the others
    }
  },
});

/**
 * Offer data as a file download from the browser
 * @param {string} data - data to download
 * @param {string} contentType - MIME type
 * @param {string} fileName - name of downloaded file
 */
export function downloadFile(data, contentType, fileName) {
  const blob = new Blob([data], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName || "download";
  anchor.click();
  window.URL.revokeObjectURL(url);
}
export const fileSaver = (data, fileName, fileType) => {
  let jsonData = JSON.stringify(data);
  var FileSaver = require("file-saver");
  var blob = new Blob([jsonData], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, fileName + fileType);
};

export const getAccessToken = () => {
  const tenant_id = JSON.parse(localStorage.getItem("tenantId"));
  let data = {
    user_id: JSON.parse(localStorage.getItem("userid")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
  };
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${config.env(
          "REACT_APP_URL"
        )}/flashcorp/api/v1/create_token?tenant_id=${tenant_id}`,
        data
      )
      .then((response) => {
        if (response.data.success === true && response.data.http_code === 200) {
          let token = response.data.token;
          resolve(token);
        } else {
          reject(false);
          swal({
            button: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-warning",
              closeModal: true,
            },
            title: response.data.message,
            text: "Please login again to continue",
            icon: "warning"
          }).then(
            (_) => {
              localStorage.clear();
              window.location.href = "/";
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  });
};


export const formatDateTime = (datetoFormat) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const date = new Date(datetoFormat);
  const timestampWithOffset = date.getTime();
  const offset = date.getTimezoneOffset() * 60 * 1000;
  const timestampWithoutOffset = timestampWithOffset - offset;
  const dateWithoutOffset = new Date(timestampWithoutOffset).toLocaleString('en-US', options);
  const [, month, day, year, time] = dateWithoutOffset.match(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}:\d{2} [APM]{2})/);
  return `${year}-${month}-${day} ${time}`;
}

export const formatCurrency = (amount, currencyFormat) => {
  if (amount && amount !== " " && !isNaN(amount)) {
    return (amount).toLocaleString(currencyFormat);
  }
}

export const formatNumber = (amount) => {
  if (amount && amount !== " " && !isNaN(amount)) {
    return Number(amount).toLocaleString();
  }
  return amount;
}

export const checkImageExists = (iconImage) => {
  if(!iconImage){
    return false;
  }
  if(Array.isArray(iconImage)){
    return iconImage.length > 0;
  }else{
    return iconImage.length > 0;
  }
}

export const getIconImage = (iconImage) => {
  if(!iconImage){
    return "";
  }
  if(Array.isArray(iconImage)){
    return iconImage[0];
  }else{
    return iconImage;
  }
}