import axiosInstance from "./axiosApp";

let dataset_id;

export const serviceUrls = (urlVar = null) => {
  let tenant_id = JSON.parse(localStorage.getItem("tenantId"));
  let role_name = JSON.parse(localStorage.getItem("role"));
  let user_id = JSON.parse(localStorage.getItem("userid"));
  if (urlVar) {
    urlVar = encodeURIComponent(urlVar);
    dataset_id = urlVar;
  }
  let query = window.location.search;
  let name = new URLSearchParams(query).get("tenant_name")
    ? new URLSearchParams(query).get("tenant_name")
    : JSON.parse(localStorage.getItem("query_param"));
  let userEmail = new URLSearchParams(query).get("user_email");
  let dataSourceUrl = "/datasource/api/v1";

  let appSourceUrl = "/flashcorp/api/v1";

  axiosInstance.interceptors.request.use((config) => {
    config.params = {
      ...config.params,
      user_id: JSON.parse(localStorage.getItem("userid")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    };
    return config;
  });

  return {
    heartbeat: `${dataSourceUrl}/heartbeat`,
    filterQueryRecords: `${dataSourceUrl}/filter_query_records?dataset_id=${dataset_id}&tenant_id=${tenant_id}`,
    deleteSingleMultipleRecords: `${dataSourceUrl}/delete_records?dataset_id=${dataset_id}&tenant_id=${tenant_id}&user_id=${user_id}`,
    clearAllDataFromDataset: `${dataSourceUrl}/delete_all_records?dataset_id=${dataset_id}&tenant_id=${tenant_id}&pass_key=delete all permanently`,
    importExcelData: `${dataSourceUrl}/upsert_records_by_csv?dataset_id=${dataset_id}&tenant_id=${tenant_id}`,
    saveModalData: `${dataSourceUrl}/upsert_records_by_json?dataset_id=${dataset_id}&tenant_id=${tenant_id}&user_id=${user_id}`,
    getRecordDataById: `${dataSourceUrl}/record_by_id?tenant_id=${tenant_id}`,
    fetchAllDataset: `${dataSourceUrl}/fetch_all_datasets?tenant_id=${tenant_id}`,
    getRecordsByTenantId: `${dataSourceUrl}/get_records_by_tenant_id/${tenant_id}`,
    saveDataSet: `${dataSourceUrl}/upsert_dataset?dataset_by=id&tenant_id=${tenant_id}`,
    searchDatasetByName: `${dataSourceUrl}/search_dataset_by_name`,
    setMetadata: `${dataSourceUrl}/set_metadata`,
    getMetadata: `${dataSourceUrl}/get_metadata`,
    deleteMetadataField: `${dataSourceUrl}/remove_field`,
    addMetadataField: `${dataSourceUrl}/add_field`,
    // apps api
    getInstallAppsData: `${appSourceUrl}/fc_appengine/apps/installed?tenant_id=${tenant_id}&role_name=${role_name}`,
    getAllApps: `${appSourceUrl}/fc_appengine/apps?tenant_id=${tenant_id}`,
    getPageList: `${appSourceUrl}/get_all_app_pages?dataset_name=fc_app_pages&tenant_id=${tenant_id}`,
    getAppsFlows: `${appSourceUrl}/get_all_app_flows?dataset_name=fc_app_flows&tenant_id=${tenant_id}`,
    headerSearch: `${appSourceUrl}/search?dataset_name=fc_app_flows&tenant_id=${tenant_id}`,
    getAppsLogics: `${appSourceUrl}/get_all_app_logics?dataset_name=fc_app_logics&tenant_id=${tenant_id}`,
    createApp: `${appSourceUrl}/fc_appengine/apps/create?dataset_by=name&tenant_id=${tenant_id}`,
    installApp: `${appSourceUrl}/fc_appengine/apps/install_uninstall?action=install&tenant_id=${tenant_id}`,
    uninstallApp: `${appSourceUrl}/fc_appengine/apps/install_uninstall?action=uninstall&tenant_id=${tenant_id}`,
    getSchedules: `${appSourceUrl}/get_all_app_flows?dataset_name=sys_schedule&tenant_id=${tenant_id}`,
    getScheduleshistory: `${appSourceUrl}/get_all_app_flows?dataset_name=sys_schedule_log&tenant_id=${tenant_id}`,
    // app api according to id
    getPageByAppId: `${appSourceUrl}/fc_appengine/apps/page_by_app_id`,
    getLogicByAppId: `${appSourceUrl}/fc_appengine/apps/page_by_app_id`,
    getFlowByAppId: `${appSourceUrl}/fc_appengine/apps/flow_by_app_id`,
    getFlowById: `${appSourceUrl}/fc_appengine/apps/flow_by_id`,
    executeApp: `${appSourceUrl}/fc_appengine/apps/app_execute`,
    saveAppData: `${appSourceUrl}/fc_appengine/apps/save_user_flow?tenant_id=${tenant_id}`,
    getScheduleById: `${appSourceUrl}/fc_appengine/apps/page_by_app_id?tenant_id=${tenant_id}`,
    cloneApp: `${appSourceUrl}/app_clone?tenant_id=${tenant_id}`,
    // vp api
    createNew: `/workflow/new`,
    saveRecordByJsonId: `datasource/api/v1/sys/upsert_single_record_by_json?user_id=${user_id}`,
    // user profiling api
    createTenant: `${appSourceUrl}/fc_appengine/apps/add_tenant`,
    createCustomerTenant: `${appSourceUrl}/fc_appengine/apps/add_customer_tenant`,
    login: `${appSourceUrl}/fc_appengine/apps/login`,
    tenantLogin: `${appSourceUrl}/fc_appengine/apps/tenant_login`,
    addRoles: `${appSourceUrl}/fc_appengine/apps/app_role`,
    getDeleteRoles: `${appSourceUrl}/fc_appengine/apps/app_role?tenant_id=${tenant_id}`,
    addUser: `${appSourceUrl}/fc_appengine/apps/add_new_user`,
    deleteUser: `${appSourceUrl}/fc_appengine/apps/add_new_user?tenant_id=${tenant_id}`,
    addPrivilege: `${appSourceUrl}/fc_appengine/apps/app_privileges`,
    getDeletePrivileges: `${appSourceUrl}/fc_appengine/apps/app_privileges?tenant_id=${tenant_id}`,
    getUserDetailsByDatasetName: `${appSourceUrl}/filter_query_records?tenant_id=${tenant_id}&dataset_name=fc_users`,
    checkKeyExistAppFlow: `${appSourceUrl}/check_key_exist?tenant_id=${tenant_id}`,
    getManager: `${appSourceUrl}/get_manager?tenant_id=${tenant_id}`,
    changePassword: `${appSourceUrl}/change_password`,
    resetPassWord: `${appSourceUrl}/reset_password?tenant_name=${name}&user_email=${userEmail}`,
    profileUpdate: `${appSourceUrl}/profile_update?tenant_name=${name}`,
    updateViewData: `${appSourceUrl}/view_edit_data_by_id`,
    mentionUserEmail: `${appSourceUrl}/email_filter?tenant_id=${tenant_id}`,
    upsertComment: `${appSourceUrl}/upsert_comment`,
    upsert_schedule_record: `${appSourceUrl}/upsert_schedule_record`,
    deleteScheduleRecord: `${appSourceUrl}/delete_record?dataset_name=sys_schedule&tenant_id=${tenant_id}`,
    generateAuthKey: `${appSourceUrl}/create_api_key`,
    getUserEmails: `${appSourceUrl}/email_filter?tenant_id=${tenant_id}`,
    generateAppLink: `${appSourceUrl}/generate_app_link`,
    getAllSharedAppLinks: `${appSourceUrl}/get_app_links?tenant_id=${tenant_id}`,
    runApp: `${appSourceUrl}/run_app`,
    importDatasetlData: `${appSourceUrl}/upsert_field?tenant_id=${tenant_id}`,
    page_clone: `${appSourceUrl}/page_clone?tenant_id=${tenant_id}`,
    logic_clone: `${appSourceUrl}/logic_clone?tenant_id=${tenant_id}`,
    flow_clone: `${appSourceUrl}/flow_clone?tenant_id=${tenant_id}`,
    addNewApi: `${appSourceUrl}/api_as_logic`,
    getApiById: `${appSourceUrl}/get_api_by_id?tenant_id=${tenant_id}`,
    getApiSpecification: `${appSourceUrl}/api_specification?tenant_id=${tenant_id}`,
    getApiLogsData: `${appSourceUrl}/get_logs_data?tenant_id=${tenant_id}`,
    searchApiByName: `${appSourceUrl}/search_api_by_name?tenant_id=${tenant_id}`,
    userSubscription: `${appSourceUrl}/tenant_subscription_details`,
    userSubscriptionAlert: `${appSourceUrl}/subscription_alert`,
    getTenants: `${appSourceUrl}/get_all_app_flows?dataset_name=sys_tenant&tenant_id=${tenant_id}`,
    getSearchAppFlows: `${appSourceUrl}/get_all_app_flows?dataset_name=sys_search_configuration&tenant_id=${tenant_id}`,
    fetchInstallAppWithPages: `${appSourceUrl}/privilege_res?tenant_id=${tenant_id}`,
    getTenantDetails: `${appSourceUrl}/get_tenant_detail`,
    logout: `${appSourceUrl}/fc_appengine/apps/logout`,
    importDatasetFields: `${appSourceUrl}/get_dataset_name?tenant_id=${tenant_id}`,
    importLogicData: `${appSourceUrl}/export_logic_data`,
    // store management
    storeListDetails: `${appSourceUrl}/store?tenant_id=${tenant_id}`,
    productList: `${appSourceUrl}/products?tenant_id=${tenant_id}`,
    cloneTenant: `${appSourceUrl}/copy_tenant`,
    upsertSingleRecord: `${appSourceUrl}/upsert_single_record`,
    getAttributeSetList: `${appSourceUrl}/get_all_app_logics?tenant_id=${tenant_id}&dataset_name=attributes`,
    upsertAttributeSet: `${appSourceUrl}/upsert_single_record`,
    fetchTagsByStoreId: `${appSourceUrl}/tags`,
    fetchCategoryStore: `${appSourceUrl}/category`,
    fetchRelatedProductFromCategories: `${appSourceUrl}/get_products_by_category`,
    uploadFileToAws: `${appSourceUrl}/upload_file_s3?tenant_id=${tenant_id}`,
    category: `${appSourceUrl}/category?tenant_id=${tenant_id}`,
    categorySet: `${appSourceUrl}/category_set?tenant_id=${tenant_id}`,
    getStoreDetails: `${appSourceUrl}/get_store_details?tenant_id=${tenant_id}`,
    exportImportProduct: `${appSourceUrl}/bulk_upload_products?tenant_id=${tenant_id}`,
    getCategorieById: `${appSourceUrl}/fc_appengine/apps/logic_by_app_id?tenant_id=${tenant_id}`,
    filterProductsRecord: `${appSourceUrl}/filter_query_records?tenant_id=${tenant_id}`,
    allUserList: `${appSourceUrl}/get_all_app_flows?dataset_name=fc_users&tenant_id=${tenant_id}`,
    saveFilters: `${appSourceUrl}/save_filter?tenant_id=${tenant_id}`,
    getAllLogicData: `${appSourceUrl}/get_all_app_logics?tenant_id=${tenant_id}`,
    form_page_list: `${appSourceUrl}/filter_query_records?tenant_id=${tenant_id}&dataset_name=fc_app_pages`,
    productValidation: `${appSourceUrl}/Add_product_validate`,
    singleProduct: `${appSourceUrl}/product_by_id`,
    addProductToWishlist: `${appSourceUrl}/add_product_to_list`,
    filterQueryData: `${appSourceUrl}/filter_query_records?tenant_id=${tenant_id}`,
    viewWishlist: `${appSourceUrl}/view_wishlist`,
    getRecordById: `${appSourceUrl}/record_by_id?tenant_id=${tenant_id}`,
    deleteWishlist: `${appSourceUrl}/delete_record?tenant_id=${tenant_id}`,
    createOrder: `${appSourceUrl}/createOrder?tenant_id=${tenant_id}`,
    getPaymentOrder: `${appSourceUrl}/order`,
    getCustomerRate: `${appSourceUrl}/fc_appengine/apps/customer-subscription`,
    updateCustomerSubscription: `${appSourceUrl}/update_customer_subscription`,
    createPayment: `${appSourceUrl}/instamojo_payment?tenant_id=${tenant_id}`,
    createPaymentGet: `${appSourceUrl}/instamojo_payment?tenant_id=${tenant_id}`,
    sendEmailSubAdmin: `${appSourceUrl}/send_email_subadmin?tenant_id=${tenant_id}`,
    fetchAllStoreComponentPages: `${appSourceUrl}/filter_query_records?tenant_id=${tenant_id}`,
    getInstallAppsBadges: `${appSourceUrl}/appbadges?tenant_id=${tenant_id}&role_name=${role_name}`,
    updateSubscription: `${appSourceUrl}/updatesubscription`,
    importExportUser: `${appSourceUrl}/export_import_user?tenant_id=${tenant_id}`,
    importDataFromDataset: `${appSourceUrl}/upsert_records_by_csv?tenant_id=${tenant_id}`,
    importExportApp: `${appSourceUrl}/importexportapp?tenant_id=${tenant_id}`,
    addToCart: `${appSourceUrl}/customercart?tenant_id=${tenant_id}`,
    cartConfig: `${appSourceUrl}/cart_config?tenant_id=${tenant_id}`,
    schedule_run: `${appSourceUrl}/schedule_run?tenant_id=${tenant_id}`,
  };
};
