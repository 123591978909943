import React, { useEffect, useState } from "react";
import { actionTypes, componentType, fileType } from "../../common/model/Model";
import ReactFileReader from "react-file-reader";
import {
  downloadXmlData,
  executeAppForActions,
  reactAppFormExecutionVP,
  importDataForTable,
  executeLogicByName,
  executeAppForTempStorage,
} from "../../../services/API";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import CustomForm from "../components/CustomForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRecordDataById } from "../../../services/tableConfigService";
import { importLogicTableData } from "../../../services/appService";
import { importDataViaDataset } from "../../../services/appService";
import { useHistory, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const TableHeaderButton = ({
  actionButtonList,
  appSessionId,
  tableName,
  sysFilter,
  routePageToOneStepBack,
  currentTableData,
  setMultipleActions,
  multipleActions,
  reloadComponentData,
  formFieldsList = [],
  formConfig,
  executeAppObject,
  addComponentToReloadArray,
  objData,
  pageFieldsList,
  pageId,
}) => {
  const [isLoadingButtonResponse, setIsLoadingButtonResponse] = useState("");
  const [formData, setFormData] = useState({});
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [currentActionDetails, setCurrentActionDetails] = useState({});
  const [formConfigData, setFormConfigData] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    setFormData({});
  }, [params.flowId]);

  useEffect(() => {
    if (Object.keys(currentActionDetails)?.length > 0) {
      getPageDetails();
    }
  }, [currentActionDetails]);

  let commonObject = {
    app_session_id: appSessionId,
    role_name: JSON.parse(localStorage.getItem("role")),
    sessionid: JSON.parse(localStorage.getItem("session_id")),
    tenant_id: JSON.parse(localStorage.getItem("tenantId")),
    user_id: JSON.parse(localStorage.getItem("userid")),
  };

  const xmlDownloadClick = (actionData) => {
    if (actionData?.logicNameValue) {
      setIsLoadingButtonResponse(actionData?.action_id);
      downloadXmlData(appSessionId, actionData?.logicNameValue, sysFilter)
        .then((response) => {
          let xmlResponse = response?.data?.value;
          var FileSaver = require("file-saver");
          var blob = new Blob([xmlResponse], {
            type: "text/plain;charset=utf-8",
          });
          if (tableName) {
            FileSaver.saveAs(blob, tableName + ".xml");
          } else {
            let formattedDate = new Date()
              .toISOString()
              .replace(/[-:T]/g, "")
              .slice(0, 14);
            let fileName = "Download_" + formattedDate;
            FileSaver.saveAs(blob, fileName + ".xml");
          }
        })
        .catch((error) => toast.error(error?.message))
        .finally(() => setIsLoadingButtonResponse(""));
    }
  };

  const onActionFileUploadClick = (file, actionData) => {
    let objBody = {
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
      data: [file?.base64],
    };
    setIsLoadingButtonResponse(actionData?.action_id);
    executeAppForActions(objBody)
      .then((response) => {
        if (response) {
          if (response?.message) {
            toast.success(response?.message);
            reloadComponentData();
          }
        }
      })
      .catch((error) => toast.error(error?.message))
      .finally(() => setIsLoadingButtonResponse(""));
  };

  const onActionButtonClickConfirmation = (actionData) => {
    confirmAlert({
      message: "Please confirm if you want to perform " + (actionData.actionName || "this") + " action?",
      buttons: [
        {
          label: "Yes",
          onClick: () => onActionButtonClick(actionData),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const onActionButtonClick = (actionData) => {
    let objBody = {
      app_session_id: appSessionId,
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
      data: [
        ...currentTableData.filter((data) =>
          multipleActions.includes(data?._id?.$oid)
        ),
      ],
    };
    setIsLoadingButtonResponse(actionData?.action_id);
    if (actionData?.transitionAppId && actionData?.transitionPageId) {
      executeAppForTempStorage(objBody);
      let link = `/app_ui/${actionData?.transitionAppId}/${actionData?.transitionPageId}`;
      history.push(link);
    } else {
      executeAppForActions(objBody)
        .then((response) => {
          if (response) {
            if (response?.message) {
              toast.success(response?.message);
              setMultipleActions([]);
              routePageToOneStepBack();
              addComponentToReloadArray(actionData?.onActionReloadComponent);
            }
          }
        })
        .catch((error) => toast.error(error?.message))
        .finally(() => setIsLoadingButtonResponse(""));
    }
  };

  const exportFileClick = (actionData) => {
    let body = {
      ...commonObject,
      ...executeAppObject,
      export: true,
      page_field: pageFieldsList,
    };

    if (actionData?.logicNameId && actionData?.referenceValue) {
      body.data.logic = [actionData?.logicNameValue];
      body.referenceValue = [{ refValue: actionData?.referenceValue }];
    }

    if (actionData?.selectedForm?.value) {
      let objData = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "fc_app_pages",
        id: actionData?.selectedForm?.value,
      };
      setIsLoadingButtonResponse(actionData?.action_id);
      getRecordDataById(objData)
        .then((response) => {
          body.page_field = response?.data?.page_fields;
          exportData(body);
        })
        .catch((err) => toast.error(err.message));
    } else {
      setIsLoadingButtonResponse(actionData?.action_id);
      exportData(body);
    }
  };

  const exportData = (body) => {
    importLogicTableData({ body })
      .then((response) => {
        var FileSaver = require("file-saver");
        var blob = new Blob([response.replace(/<\/?[^>]+(>|$)/g, "")], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, tableName + ".csv");
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsLoadingButtonResponse(""));
  };

  const downloadLinkFileClick = (actionData) => {
    let bodyObj = {
      app_session_id: appSessionId,
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
    };
    setIsLoadingButtonResponse(actionData?.action_id);
    executeLogicByName(bodyObj)
      .then((response) => {
        if (response) {
          const URL = response.data.value;
          window.location.href = URL.data;
        }
      })
      .catch((err) => toast.error(err?.message))
      .finally(() => setIsLoadingButtonResponse(""));
  };

  const onLinkAction = (action) => {
    let link;
    if (action?.transitionAppId && action?.transitionPageId) {
      link = `/app_ui/${action?.transitionAppId}/${action?.transitionPageId}`;
    } else {
      link = `/not-configured`;
    }
    history.push(link);
  };

  const getPageDetails = () => {
    if (currentActionDetails?.selectedForm?.value) {
      let objData = {
        tenant_id: JSON.parse(localStorage.getItem("tenantId")),
        dataset_name: "fc_app_pages",
        id: currentActionDetails?.selectedForm?.value,
      };
      setIsFormLoading(true);
      getRecordDataById(objData)
        .then((response) => {
          if (response.success === true) {
            let {
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            } = response?.data;
            setFormConfigData({
              page_fields,
              form_disclaimer,
              form_output_key,
              logic_name_value,
              formGroupData,
            });
          }
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setIsFormLoading(false));
    } else {
      setFormConfigData({
        page_fields: formFieldsList,
        form_disclaimer: formConfig?.form_disclaimer,
        form_output_key: formConfig?.form_output_key,
        logic_name_value: formConfig?.logic_name_value,
        formGroupData: formConfig?.formGroupData,
      });
    }
  };

  const addSubmitForm = () => {
    let objectBody = {
      ...commonObject,
      data: {
        page_fields: [
          formConfigData?.page_fields.map((field) => {
            return {
              is_required: field.is_required,
              display_name: field?.display_name,
              name: field?.name,
            };
          }),
        ],
        formData: [
          {
            ...formData,
            user_id: JSON.parse(localStorage.getItem("userid")),
          },
        ],
        logic_to_send: [formConfigData?.logic_name_value],
      },
      page_type: componentType.formComponent,
      store_output_key: formConfigData?.form_output_key,
    };
    setSubmitResponseLoading(true);
    reactAppFormExecutionVP(objectBody)
      .then((response) => {
        toast.success(response?.message);
        setModalState(false);
        setFormData({});
        setCurrentActionDetails({});
        setFormData({});
        setFormConfigData({});
        reloadComponentData();
      })
      .catch((error) => toast.error(error?.message))
      .finally(() => setSubmitResponseLoading(false));
  };

  const onFileImport = (file, actionData) => {
    let data = new FormData();
    data.append("file", file[0]);
    data.append(
      "data",
      JSON.stringify({
        page_fields: [objData?.page?.page_fields],
        logic_to_send: actionData?.logicNameValue,
      })
    );
    data.append("store_output_key", actionData?.referenceValue);
    data.append("flow_id", objData?.flow_id);
    data.append("page_type", objData?.page?.page_type);
    data.append("page_id", pageId);
    data.append("app_session_id", appSessionId);
    data.append("role_name", commonObject?.role_name);
    data.append("sessionid", commonObject?.sessionid);
    data.append("tenant_id", commonObject?.tenant_id);
    data.append("user_id", commonObject?.user_id);
    data.append("sheet_name", actionData?.xlsxSheetName || "");
    data.append("file_type", actionData?.fileType || fileType.csv);
    data.append(
      "incorrect_data_ref_var",
      actionData?.incorrect_data_ref_var || ""
    );
    setIsLoadingButtonResponse(actionData?.action_id);
    importDataForTable(data)
      .then((response) => {
        toast.success(response?.message);
      })
      .catch((error) => {
        if (error?.status === 400) {
          let jsonData = error?.incorrect_data;
          var FileSaver = require("file-saver");
          var blob = new Blob([jsonData], {
            type: "text/csv;charset=utf-8",
          });
          FileSaver.saveAs(blob, "incorrect_data" + ".csv");
        }
        toast.error(error?.message);
      })
      .finally(() => {
        setIsLoadingButtonResponse("");
        reloadComponentData();
      });
  };


  const onFileImportDataFromDataset = (file, actionData) => {
    let data = new FormData();
    data.append("data_file", file[0]);
    let dataset_id = actionData?.datasetId;
    setIsLoadingButtonResponse(actionData?.action_id);
    importDataViaDataset(data, dataset_id)
      .then((response) => {
        toast.success(response?.message);
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setIsLoadingButtonResponse("");
        reloadComponentData();
      });
  };

  return (
    <>
      {actionButtonList
        ?.filter((action) => action.buttonPlacement === "Header")
        .map((action, index) => {
          if (
            action?.actionType === actionTypes.button ||
            action?.actionType === undefined
          ) {
            return (
              <button
                actionbutton="true"
                key={index}
                disabled={multipleActions.length <= 0}
                className={`btn text-light btn-md m-0 me-2 text-nowrap ${multipleActions.length <= 0 && "cursorNotAllowed"
                  }`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={() => onActionButtonClickConfirmation(action)}
              >
                {isLoadingButtonResponse === action?.action_id &&
                  action?.action_id ? (
                  <div className="spinner-border text-light spinner-border-sm me-1" />
                ) : (
                  action?.buttonIcon?.value && (
                    <FontAwesomeIcon
                      className="me-1"
                      icon={action?.buttonIcon?.value}
                    />
                  )
                )}
                {action.actionName}
              </button>
            );
          } else if (action?.actionType === actionTypes.fileUpload) {
            return (
              <div className=" d-inline-block" key={index}>
                <ReactFileReader
                  disabled={isLoadingButtonResponse.length > 0}
                  key={index}
                  base64={true}
                  fileTypes={[".csv", ".xlsx", ".xls"]}
                  handleFiles={(file) => onActionFileUploadClick(file, action)}
                  multipleFiles={false}
                >
                  <button
                    actionbutton="true"
                    className="btn text-light btn-md m-0 me-2 text-nowrap"
                    style={{ backgroundColor: action.buttonColor }}
                  >
                    {isLoadingButtonResponse === action?.action_id &&
                      action?.action_id ? (
                      <div className="spinner-border text-light spinner-border-sm me-1" />
                    ) : (
                      action?.buttonIcon?.value && (
                        <FontAwesomeIcon
                          className="me-1"
                          icon={action?.buttonIcon?.value}
                        />
                      )
                    )}
                    {action.actionName}
                  </button>
                </ReactFileReader>
              </div>
            );
          } else if (action?.actionType === actionTypes.downloadXml) {
            return (
              <button
                disabled={isLoadingButtonResponse}
                actionbutton="true"
                key={index}
                className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={(e) => xmlDownloadClick(action)}
              >
                {isLoadingButtonResponse === action?.action_id &&
                  action?.action_id ? (
                  <div className="spinner-border text-light spinner-border-sm me-1" />
                ) : (
                  action?.buttonIcon?.value && (
                    <FontAwesomeIcon
                      className="me-1"
                      icon={action?.buttonIcon?.value}
                    />
                  )
                )}
                {action.actionName}
              </button>
            );
          } else if (action?.actionType === actionTypes.export) {
            return (
              <button
                disabled={isLoadingButtonResponse}
                actionbutton="true"
                key={index}
                className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={() => exportFileClick(action)}
              >
                {isLoadingButtonResponse === action?.action_id ? (
                  <div className="spinner-border spinner-border-sm me-1" />
                ) : (
                  action?.buttonIcon?.value && (
                    <FontAwesomeIcon
                      className="me-1"
                      icon={action?.buttonIcon?.value}
                    />
                  )
                )}
                {action.actionName}
              </button>
            );
          } else if (action?.actionType === actionTypes.addForm) {
            return (
              <button
                disabled={isLoadingButtonResponse}
                actionbutton="true"
                key={index}
                className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={() => {
                  setModalState(true);
                  setCurrentActionDetails(action);
                  setFormData({});
                }}
              >
                {action?.buttonIcon?.value && (
                  <FontAwesomeIcon
                    className="me-1"
                    icon={action?.buttonIcon?.value}
                  />
                )}
                {action.actionName}
              </button>
            );
          } else if (action?.actionType === actionTypes.import) {
            let file_type = [action?.fileType || fileType.csv];
            return (
              <ReactFileReader
                disabled={isLoadingButtonResponse.length > 0}
                key={index}
                fileTypes={file_type}
                handleFiles={(file) => onFileImport(file, action)}
                multipleFiles={false}
              >
                <button
                  disabled={isLoadingButtonResponse}
                  actionbutton="true"
                  key={index}
                  className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                  style={{ backgroundColor: action.buttonColor }}
                >
                  {isLoadingButtonResponse === action?.action_id ? (
                    <div className="spinner-border spinner-border-sm me-1" />
                  ) : (
                    action?.buttonIcon?.value && (
                      <FontAwesomeIcon
                        className="me-1"
                        icon={action?.buttonIcon?.value}
                      />
                    )
                  )}
                  {action.actionName}
                </button>
              </ReactFileReader>
            );
          } else if (action?.actionType === actionTypes.importDataset) {
            let file_type = [action?.fileType || fileType.csv];
            return (
              <ReactFileReader
                disabled={isLoadingButtonResponse.length > 0}
                key={index}
                fileTypes={file_type}
                handleFiles={(file) => onFileImportDataFromDataset(file, action)}
                multipleFiles={false}
              >
                <button
                  disabled={isLoadingButtonResponse}
                  actionbutton="true"
                  key={index}
                  className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                  style={{ backgroundColor: action.buttonColor }}
                >
                  {isLoadingButtonResponse === action?.action_id ? (
                    <div className="spinner-border spinner-border-sm me-1" />
                  ) : (
                    action?.buttonIcon?.value && (
                      <FontAwesomeIcon
                        className="me-1"
                        icon={action?.buttonIcon?.value}
                      />
                    )
                  )}
                  {action.actionName}
                </button>
              </ReactFileReader>
            );
          }
          else if (action?.actionType === actionTypes.downloadLink) {
            return (
              <button
                disabled={isLoadingButtonResponse}
                actionbutton="true"
                key={index}
                className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={() => downloadLinkFileClick(action)}
              >
                {isLoadingButtonResponse === action?.action_id ? (
                  <div className="spinner-border spinner-border-sm me-1" />
                ) : (
                  action?.buttonIcon?.value && (
                    <FontAwesomeIcon
                      className="me-1"
                      icon={action?.buttonIcon?.value}
                    />
                  )
                )}
                {action.actionName}
              </button>
            );
          } else if (action?.actionType === actionTypes.link) {
            return (
              <button
                disabled={isLoadingButtonResponse}
                actionbutton="true"
                key={index}
                className={`btn text-light btn-md m-0 me-2 text-nowrap`}
                style={{ backgroundColor: action.buttonColor }}
                onClick={() => onLinkAction(action)}
              >
                {action?.buttonIcon?.value && (
                  <FontAwesomeIcon
                    className="me-1"
                    icon={action?.buttonIcon?.value}
                  />
                )}
                {action.actionName}
              </button>
            );
          }
        })}

      <Modal
        dialogClassName="modal-fullscreen"
        scrollable
        centered
        size="xl"
        show={modalState}
        onHide={() => {
          setModalState(false);
          setCurrentActionDetails({});
          setFormData({});
          setFormConfigData({});
        }}
        backdrop={submitResponseLoading ? "static" : true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative react_ui px-2">
            {isFormLoading && (
              <div className="hierarchyForm">
                <span>
                  <div className="spinner-border spinner-border-sm" />
                  <span className="ms-1">Please Wait...</span>
                </span>
              </div>
            )}
            <CustomForm
              formGroupData={formConfigData?.formGroupData}
              pageFields={formConfigData?.page_fields}
              isEdit={false}
              setFormData={setFormData}
              formData={formData}
              appSessionId={appSessionId}
              formTitle={""}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setModalState(false);
              setFormData({});
            }}
            className="btn btn-md btn-danger "
          >
            <FontAwesomeIcon icon="fa fa-times" />
            <span className="ms-1">Close</span>
          </button>

          <button
            type="button"
            onClick={addSubmitForm}
            className="btn btn-md btn-success "
          >
            {submitResponseLoading ? (
              <div className="spinner-border spinner-border-sm text-light" />
            ) : (
              <FontAwesomeIcon icon="fa fa-check-double" />
            )}
            <span className="ms-1">Submit</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableHeaderButton;
