import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import { mentionUserEmail } from "../../../services/appService";
import { executeAppForActions } from "../../../services/API";
import CustomForm from "../components/CustomForm";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApproveModal = ({
  handleModalClose,
  showApproveModal,
  serviceRowData,
  currentActionDetails,
  appSessionId,
  routePageToOneStepBack,
  pageFields,
  addComponentToReloadArray,
}) => {
  const history = useHistory();

  const defaultStyle = {
    control: {
      width: "100%",
      height: "50px",
    },

    suggestions: {
      marginTop: "30px",
      marginLeft: "10px",

      list: {
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
      },

      item: {
        padding: "5px 15px",
        borderBottom: "1px solid #d7d7d7",

        "&focused": {
          backgroundColor: "#f6f7fb",
          color: "#2c89b7",
        },
      },
    },
  };
  const [commentInput, setCommentInput] = useState("");
  const [matches, setMatches] = useState();
  const [userName, setUserName] = useState("");
  const [modalFormData, setModalFormData] = useState({});
  const [modalFormFields] = useState(
    pageFields
      ?.filter((field) => field?.show_on_actions === "yes")
      .map((field) => {
        return { ...field, is_hidden: "false" };
      })
  );
  const [responseLoading, setResponseLoading] = useState(false);

  useEffect(() => {
    if (showApproveModal) {
      callUserEmails();
    } else {
      setModalFormData({});
      setMatches("");
      setCommentInput("");
    }
  }, [showApproveModal]);

  const onModalButtonClick = (e) => {
    e.preventDefault();
    let servicesObject = {
      ...serviceRowData,
      ...modalFormData,
      email_list: matches,
    };
    executeAppAction(servicesObject, currentActionDetails);
  };

  const onUserTag = (e) => {
    const commentInput = e.target.value;
    setCommentInput(commentInput);
    let valueData = commentInput.replace(/ *\([^)]*\) */g, "");
    let matches = valueData;
    setMatches(matches);
  };

  const callUserEmails = () => {
    mentionUserEmail()
      .then((response) => {
        if (response) {
          setUserName(response.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const executeAppAction = (rowData, actionData) => {
    let objBody = {
      app_session_id: appSessionId,
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: JSON.parse(localStorage.getItem("userid")),
      logic_name: actionData.logicNameValue || "",
      reference_name: actionData.referenceValue || "",
      data: [rowData],
    };
    setResponseLoading(true);
    executeAppForActions(objBody)
      .then((response) => {
        if (response) {
          if (response?.message) {
            handleModalClose();
            toast.success(response?.message);
            routePageToOneStepBack();
            addComponentToReloadArray(actionData?.onActionReloadComponent);
            if (actionData?.transitionAppId && actionData?.transitionPageId) {
              let link = `/app_ui/${actionData?.transitionAppId}/${actionData?.transitionPageId}`;
              history.push(link);
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setResponseLoading(false);
      });
  };

  return (
    <Modal
      show={showApproveModal}
      onHide={handleModalClose}
      backdrop={responseLoading ? "static" : true}
    >
      <Modal.Header closeButton>
        <h5>Confirm Popup</h5>
      </Modal.Header>
      <Modal.Body>
        {`Please confirm if you want to perform ${currentActionDetails?.actionName} action?`}
        <br />
        <div className="row">&nbsp;</div>

        <CustomForm
          pageFields={modalFormFields || []}
          isEdit={false}
          setFormData={setModalFormData}
          formData={modalFormData}
          appSessionId={appSessionId}
          formTitle={""}
        />

        <div className="col-md-12 p-0">
          <p className={"mb-1"}>
            Do you want to send email notification to any additional people? Start by typing @
          </p>
          <MentionsInput
            singleLine
            allowSpaceInQuery
            style={defaultStyle}
            value={commentInput}
            onChange={onUserTag}
            className="custom-comment"
          >
            <Mention trigger={/(\@([A-Za-z0-9_.]*))$/} data={userName} />
          </MentionsInput>
        </div>
        <div className="text-end">
          <button
            actionbutton="true"
            style={{ backgroundColor: currentActionDetails?.buttonColor }}
            className="btn btn-md border-0 text-white"
            onClick={onModalButtonClick}
          >
            {responseLoading ? (
              <div className="spinner-border text-light spinner-border-sm me-1" />
            ) : (
              currentActionDetails?.buttonIcon?.value && (
                <FontAwesomeIcon
                  className="me-1"
                  icon={currentActionDetails?.buttonIcon?.value}
                />
              )
            )}
            {currentActionDetails?.actionName}
          </button>

          <Button
            variant="secondary"
            className="btn-secondary btn-md border-0 me-0"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveModal;
