import axios from "axios";
import config from "../config";

// Retry interceptor function
import axiosRetry from 'axios-retry';

// Pass the axios instance to the retry function  and call it
axiosRetry(axios, { 
  retries: 1, // Number of retries
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000), // Exponential delay with backoff of 1000ms
  retryCondition(error) {
    // Conditional check the error status code
    switch (error.response.status) {
      case 500:
        return true; // Retry request with response status code 500
      case 400:
        return true; // Retry request with response status code 400
      default:
        return false; // Do not retry the others
    }
  },
});

// Generate axios instance
const nojwtInstance = axios.create({
  baseURL: config.env("REACT_APP_URL"),
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});


nojwtInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    throw err;
  }
  );
  
  export default nojwtInstance