import React, { useState } from "react";
import "./checkboxRJSF.scss";

function CheckboxRJSF(props) {
  const [selectedValues, setSelectedValues] = useState(props?.value || []);
  const handleChange = (newValue) => {
    // If the selected number exceeds the limit, don't update the state
    const len = Number(props?.element?.length | 0);
    if (len > 0 && selectedValues.length >= len && !selectedValues.includes(newValue)) {
      return;
    }

    const newSelectedValues = selectedValues.includes(newValue)
      ? selectedValues.filter(val => val !== newValue)
      : [...selectedValues, newValue];

    setSelectedValues(newSelectedValues);
    props?.onChange(newSelectedValues);
  };

  return (
    <div className={`mt-1 checkboxes ${props?.className || ""}`}>
      {props?.options.enumOptions.map((option, index) => (
        <label  key={index}
          className={`me-2 mb-0 checkbox-inline ${
            props?.readonly ? "disableCheckbox" : ""
          }`}
        >
          <span>
            <input
              disabled={props?.readonly || props?.disabled}
              value={option.value}
              type="checkbox"
              checked={selectedValues.includes(option.value)}
              onChange={() => handleChange(option.value)}
            /><span>{option.label}</span>
          </span>
        </label>
      ))}
    </div>
  );
}

export default CheckboxRJSF;
